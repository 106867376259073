import React, { useState, useEffect, useRef } from "react";
import Typewriter from "typewriter-effect";
import scandishmockup from "../assets/img/scandishmockup.png";
import "../styles/Scandishreels.css";
import { motion } from "framer-motion";
import {
  useSearchParams,
  createSearchParams,
  useNavigate,
  useLocation,
  Link,
} from "react-router-dom";

export default function ScandishReels() {
  const navigate = useNavigate();
  const windowSize = useRef([window.innerWidth, window.innerHeight]);
  console.log(windowSize.current);

  useEffect(()=>{document.title = "KlutchKyle's Portfolio | Scandish Reels"},[])

  return (
    <motion.div
      className="scandish-reels-container"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5 }}
    >
      <h1 className="ytdownloader-header">Scandish Reels</h1>

      <h4 className="ytdownlaoder-collab-text">
        In collaboration with{" "}
        <a href="https://poieo-dev.com/">Poieo Dev</a>
      </h4>
      <img className="ytdownloader-mockup" src={scandishmockup}></img>
      <div
        className="scandish-visit-button"
        onMouseDown={() => window.open("https://scandishhome.com/reels")}
      >
        Visit
      </div>
    </motion.div>
  );
}
